<template>
<div class="">

  <div class="lg:min-h-screen flex items-center justify-center py-12 lg:-mt-10 px-4 sm:px-6 lg:px-8">
  <div class="max-w-md w-full space-y-8">
    <div>
      <img class="mx-auto w-auto pb-6" src="https://tbff-assets.ams3.cdn.digitaloceanspaces.com/images/lbsqtrans.png">
      <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
        Reset Password
      </h2>
    </div>
    <form class="mt-8 space-y-6" ref="form" @submit.prevent="resetPassword">
      <input type="hidden" name="remember" value="true">
      <div class="rounded-md shadow-sm -space-y-px">
        <div>
          <label for="email-address" class="sr-only">Email address</label>
          <input v-model="email" id="email-address" name="email" type="email" autocomplete="email" required class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 rounded-t-md text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Email address">
        </div>
        <div>
          <label for="password" class="sr-only">New Password</label>
          <input v-model="password" id="password" name="password" type="password" autocomplete="current-password" required class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="New Password">
        </div>
        <div>
          <label for="password_confirmation" class="sr-only">Confirm New Password</label>
          <input v-model="password_confirmation" id="password_confirmation" name="password_confirmation" type="password" autocomplete="current-password" required class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Confirm New Password">
        </div>
      </div>

      <div>
        <button type="submit" class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          Reset Password
        </button>
      </div>


      <input type="hidden" id="token" v-model="token" name="token">

    </form>

  </div>

</div>



<FlashMessage :error="error" @close="error = null" />
</div> 
</template>

<script>

import { getError } from "@/utils/helpers";
import AuthService from "@/services/AuthService";
import FlashMessage from "@/components/FlashMessage";

export default {
    name: 'Register',
  components: {
    FlashMessage,
  },
  data () {
    return {
      email: null,
      password: null,
      password_confirmation: null,
      token: null,
      error: null,
      message: null,
    }
  },
  methods: {
      resetPassword() {
        this.error = null;
        this.message = null;
        const payload = {
          token: this.token,
          email: this.email,
          password: this.password,
          password_confirmation: this.password_confirmation,
        };
        AuthService.resetPassword(payload)
          .then(() => this.$router.push("/"))
          .catch((error) => (this.error = getError(error)));
      },
  },
  mounted() {
    this.token = this.$route.query.token;
    this.email = this.$route.query.email;
  }
}
</script>